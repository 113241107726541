<template>
    <div class="direction-list">
        <a class="direction-list__item" v-for="item in directions" :key="item.id" :style="`background-color: ${colors[item.code.toLowerCase()] || '#000'}`" @click="clickHandle(item.id)">
            <img class="direction-list__icon" src="@/assets/images/svg/logo-small.svg">
            <p class="direction-list__title">{{ item.name }}</p>
        </a>
    </div>
</template>

<script lang="js">
import { mapActions, mapGetters } from 'vuex';
import colors from '@/helpers/DirectionColors';
import entity from '@/helpers/FetchEntities';

export default {
    name: 'SidebarDirectionList',
    data() {
        return {
            colors
        }
    },
    methods: {
        async clickHandle(id) {
            this.startLoading();

            try {
                this.selectDirection([id]);

                const actions = {
                    setEntities: this.setEntities,
                    setFilterId: this.setFilterId,
                    setCount: this.setCount
                };

                await entity.filter(this.layer, this.bounds, this.filterValues, actions);
                this.$router.push({ path: `/${this.layer}/filters` });
            } catch (error) {
                console.error('Ошибка ', error);
            } finally {
                this.stopLoading();
            }
        },
        ...mapActions('loader', ['startLoading', 'stopLoading']),
        ...mapActions('filter', ['selectDirection', 'setFilterId', 'setCount']),
        ...mapActions('entity', ['setEntities'])
    },
    computed: {
        ...mapGetters('layer', ['layer']),
        ...mapGetters('filter', ['filterValues', 'filterId', 'directions']),
        ...mapGetters('map', ['bounds'])
    }
}
</script>

<style lang="scss" scoped>
.direction-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 16px;

  &__item {
    display: flex;
    align-items: center;
    width: 48.5%;
    padding: 8px;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  &__icon {
    width: 41px;
    height: 40px;
    margin-right: 5px;
  }

  &__title {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 16px;
    line-height: 14px;
    margin-left: 1px;
  }
}
</style>
